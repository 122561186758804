const other = true;
const multiSelect = true;
const multiLine = true;
const editable = true;

export default [
  {
    field: 'aboriginal',
    name: 'First Nations Connection',
    type: 'select',
    useKeywords: true,
    default: 'Unknown',
    section: 'main',
    editable,
  },
  {
    field: 'status',
    name: 'Status',
    type: 'select',
    options: [
      'Approved',
      'Allocated Assessor',
      'Commenced Training',
      'Waiting',
      'Temporarily Inactive',
      'Transferred',
      'Withdrawn',
      'Not Suitable',
      'Deregistered',
      'Closed',
    ],
    section: 'main',
    editable,
  },
  {
    field: 'approvalDate',
    name: 'Date Approved',
    type: 'date',
    section: 'main',
    editable,
  },
  {
    field: 'created',
    name: 'Date Added',
    type: 'date',
    section: 'main',
  },
  {
    field: 'howDidTheyHear',
    name: 'How did they hear about Kennerley?',
    type: 'select',
    useKeywords: true,
    multiSelect,
    other,
    section: 'enquiry',
    editable,
  },
  {
    field: 'enquiryCareType',
    name: 'Suggested Care Type',
    type: 'select',
    useKeywords: true,
    section: 'enquiry',
    multiSelect,
    editable,
  },
  {
    field: 'enquiryDate',
    name: 'Date of Enquiry',
    type: 'date',
    section: 'enquiry',
    editable,
  },
  {
    field: 'infoSent',
    name: 'Date Information Sent',
    type: 'date',
    section: 'enquiry',
    editable,
  },
  {
    field: 'interestReceived',
    name: 'Registration of Interest Received',
    type: 'date',
    section: 'enquiry',
    editable,
  },
  {
    field: 'enquiryComments',
    name: 'Enquiry Comments',
    type: 'text',
    multiLine,
    section: 'enquiry',
    editable,
  },
  {
    field: 'firstVisit',
    name: 'Date of First Visit',
    type: 'date',
    section: 'enquiry',
    editable,
  },
  {
    field: 'cca',
    name: 'Cultural Competency Assessment',
    type: 'check',
    section: 'regulation',
    editable,
  },
  {
    field: 'covid19Vaccination',
    name: 'COVID-19 Full Vaccination',
    type: 'check',
    section: 'regulation',
    editable,
  },
  {
    field: 'reviewDue',
    name: 'Review Due',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'wwvpCheck',
    name: 'Working with Vulnerable People Check',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'wwvpcExpiry',
    name: 'Expiry of WWVP Check',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'scocExpiry',
    name: 'Expiry of Safeguarding Children Online Certificate',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcLodged',
    name: 'Police Check Carer Notification',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResults',
    name: 'Police Check Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcResultsReceived',
    name: 'PC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'pcReviewDate',
    name: 'Next PC Due',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscSent',
    name: 'Child Safety Check Sent',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResults',
    name: 'CSC Results',
    type: 'text',
    section: 'regulation',
    editable,
  },
  {
    field: 'cscResultsReceived',
    name: 'CSC Results Received',
    type: 'date',
    section: 'regulation',
    editable,
  },
  {
    field: 'dateClosed',
    name: 'Date Closed',
    type: 'date',
    section: 'main',
  },
  {
    field: 'dateInactive',
    name: 'Inactive Date',
    type: 'date',
    section: 'main',
  },
  {
    field: 'fileNumber',
    name: 'Additional Comments',
    type: 'text',
    section: 'main',
    multiLine,
    editable,
  },
];
