import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';

class WWVP extends Component {
  render() {
    const { wwvp } = this.props;
    return wwvp ? (
      <div className="dash-col">
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={wwvp.staff.map(s => (
            <PersonCard person={s} rel="staff" key={s._id} />
          ))}
          primaryText={`Staff (${wwvp.staff.length})`}
        />
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={wwvp.carers.map(s => (
            <PersonCard person={s} rel="carer" key={s._id} caller="dashboard" />
          ))}
          primaryText={`Carers (${wwvp.carers.length})`}
        />
        <ListItem
          primaryTogglesNestedList
          style={{ backgroundColor: 'white' }}
          nestedItems={wwvp.members.map(s => (
            <PersonCard
              person={s}
              rel="other"
              key={s._id}
              householdLink={<Link to={`/household/${s.household._id}`} />}
              caller="dashboard"
            />
          ))}
          primaryText={`Household Members (${wwvp.members.length})`}
        />
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No WWVP Expiring"
        />
      </div>
    );
  }
}

export default WWVP;
