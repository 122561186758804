import React, { Component } from 'react';
import { ListItem } from 'material-ui/List';

import PersonCard from '../../../components/PersonCard';

class Licence extends Component {
  render() {
    const { licence } = this.props;
    return licence ? (
      <div className="dash-col">
        <div>
          {licence.staff.map(s => (
            <PersonCard person={s} rel="staff" key={s._id} caller="dashboard" />
          ))}
        </div>
      </div>
    ) : (
      <div className="dash-col">
        <ListItem
          disabled
          style={{ backgroundColor: 'white' }}
          primaryText="No Licences Expiring"
        />
      </div>
    );
  }
}

export default Licence;
