import React, { Component } from 'react';
import moment from 'moment';
import MDatePicker from 'material-ui/DatePicker';
import IconButton from 'material-ui/IconButton';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';

import { colours, icons } from '../theme';

const styles = {
  clearIcon: { color: colours.kGrey },
  column1: { padding: 0, width: 'auto' },
  column2: { padding: 0, width: 48, verticalAlign: 'bottom' },
  clearButtonOn: { display: 'block' },
  clearButtonOff: { display: 'none' },
};

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handle_OnClick_Clear() {
    this.setState({
      searchText: '',
    });
  }

  render() {
    const {
      param,
      value,
      onUpdated,
      create,
      readOnly,
      half,
      carer,
      youngPerson,
      caseLoad,
    } = this.props;
    let floatingLabelText = param.name;
    if (create) {
      if (param.required) {
        floatingLabelText = `${param.name} (required)`;
      }
    }
    const date = { openToYearSelection: true };
    if (param.field === 'birthDate') {
      date.maxDate = new Date();
      date.maxDate.setHours(0, 0, 0, 0);
      if (carer) {
        const maxDate = new Date();
        maxDate.setFullYear(maxDate.getFullYear() - 18);
        maxDate.setHours(0, 0, 0, 0);
        date.maxDate = maxDate;
      }
      if (youngPerson) {
        const minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 20);
        minDate.setHours(0, 0, 0, 0);
        date.minDate = minDate;
      }
      if (caseLoad) {
        const { caseLoad } = this.props;
        const minDate = new Date(
          caseLoad.kennerleyEntryDate || caseLoad.created
        );
        minDate.setHours(0, 0, 0, 0);
        date.minDate = minDate;
        if (caseLoad.leftCareDate) {
          const maxDate = new Date(caseLoad.leftCareDate);
          maxDate.setHours(0, 0, 0, 0);
          date.maxDate = maxDate;
        }
      }
    }
    if (param.field === 'dateRecorded') {
      if (caseLoad) {
        const { caseLoad } = this.props;
        const minDate = new Date(
          caseLoad.kennerleyEntryDate || caseLoad.created
        );
        minDate.setHours(0, 0, 0, 0);
        date.minDate = minDate;
        if (caseLoad.leftCareDate) {
          const maxDate = new Date(caseLoad.leftCareDate);
          maxDate.setHours(0, 0, 0, 0);
          date.maxDate = maxDate;
        }
      } else {
        date.maxDate = new Date();
        date.maxDate.setHours(0, 0, 0, 0);
      }
    }
    const expiringFields = [
      'pcReviewDate',
      'wwvpcExpiry',
      'reviewDue',
      'scocExpiry',
      'checksRequiredFrom',
      'licenceExpiry',
    ];
    const now = new Date();
    const next2month = new Date();
    next2month.setMonth(next2month.getMonth() + 2);
    let expiryUnderline = {};
    const valueToShow = value ? new Date(value) : null;
    if (expiringFields.includes(param.field)) {
      if (!valueToShow || valueToShow <= now) {
        expiryUnderline = { borderColor: colours.kRed, borderWidth: '2px' };
      } else if (valueToShow <= next2month) {
        expiryUnderline = { borderColor: colours.orange, borderWidth: '2px' };
      }
    }

    return (
      <Table className="list-search" selectable={false}>
        <TableBody displayRowCheckbox={false}>
          <TableRow selectable={false}>
            <TableRowColumn style={styles.column1}>
              <MDatePicker
                id={param.field}
                fullWidth
                floatingLabelText={floatingLabelText}
                floatingLabelStyle={{
                  color: create && param.required && !value && colours.kRed,
                }}
                formatDate={d => moment(d).format('D MMMM YYYY')}
                autoOk={false}
                firstDayOfWeek={1}
                value={value ? new Date(value) : null}
                onChange={(e, val) => onUpdated(val)}
                {...date}
                disabled={readOnly || (!create && !param.editable)}
                style={half ? { display: 'inline-block', width: '50%' } : {}}
                underlineStyle={expiryUnderline}
                underlineDisabledStyle={{ borderBottom: '1px solid #e0e0e0' }}
                shouldDisableDate={date2 => {
                  if (param.field === 'birthDate') {
                    return moment(date2).isAfter(new Date(), 'day');
                  }
                  return false;
                }}
              />
            </TableRowColumn>
            {!readOnly &&
              param.editable &&
              param.clearable &&
              value && (
                <TableRowColumn style={styles.column2}>
                  <IconButton
                    iconStyle={styles.clearIcon}
                    onClick={() => onUpdated(null)}
                  >
                    <icons.ClearIcon />
                  </IconButton>
                </TableRowColumn>
              )}
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default DatePicker;
